// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/app/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import UserOutlined from '/app/node_modules/@ant-design/icons/es/icons/UserOutlined';
import SnippetsOutlined from '/app/node_modules/@ant-design/icons/es/icons/SnippetsOutlined';
import SpotifyOutlined from '/app/node_modules/@ant-design/icons/es/icons/SpotifyOutlined';
import BarsOutlined from '/app/node_modules/@ant-design/icons/es/icons/BarsOutlined';
import CrownOutlined from '/app/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import HeatMapOutlined from '/app/node_modules/@ant-design/icons/es/icons/HeatMapOutlined';
import AppstoreAddOutlined from '/app/node_modules/@ant-design/icons/es/icons/AppstoreAddOutlined';
import NotificationOutlined from '/app/node_modules/@ant-design/icons/es/icons/NotificationOutlined';
export default { SmileOutlined, UserOutlined, SnippetsOutlined, SpotifyOutlined, BarsOutlined, CrownOutlined, HeatMapOutlined, AppstoreAddOutlined, NotificationOutlined };
