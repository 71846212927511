export default {
  'menu.welcome': 'Welcome',
  'menu.login': 'Đăng nhập',
  'menu.news': 'Tin tức',
  'menu.zodiac': 'Cung hoàng đạo',
  'menu.zodiac.list': 'Danh sách',
  'menu.new_create': 'Tạo mới tin tức',
  'menu.new_edit': 'Cập nhật tin tức',
  'menu.music': 'Âm nhạc',
  'menu.music_create': 'Tạo mới âm nhạc',
  'menu.music_edit': 'Cập nhật âm nhạc',
  'menu.quote': 'Danh ngôn',
  'menu.quote.list': 'Danh sách',
  'menu.quote_create': 'Tạo mới danh ngôn',
  'menu.quote_edit': 'Cập nhật danh ngôn',
  'menu.zodiac.create': 'Tạo mới cung hoàng đạo',
  'menu.zodiac.edit': 'Cập nhật cung hoàng đạo',
  'menu.zodiac.list-celebrity': 'Người nổi tiếng',
  'menu.zodiac.create-celebrity': 'Tạo mới người nổi tiếng',
  'menu.zodiac.edit-celebrity': 'Cập nhật người nổi tiếng',
  'menu.numerology.list': 'Danh sách',
  'menu.numerology': 'Thần số học',
  'menu.numerology.create': 'Tạo mới thần số học',
  'menu.numerology.edit': 'Cập nhật thần số học',
  'menu.package': 'Package IAP',
  'menu.package.list': 'Danh sách',
  'menu.package.create': 'Tạo mới',
  'menu.package.edit': 'Cập nhật',
  'menu.numerology.list-celebrity': 'Người nổi tiếng',
  'menu.numerology.create-celebrity': 'Tạo mới người nổi tiếng',
  'menu.numerology.edit-celebrity': 'Cập nhật người nổi tiếng',
  'menu.zodiac.list-horoscopes': 'Giải nghĩa',
  'menu.numerology.list-relations': 'Mối quan hệ',
  'menu.users': 'Người dùng',
  'menu.package.list-iap': 'Gói',
  'menu.package.list-receipt': 'Giao dịch',
  'menu.tarot': 'Tarot',
  'menu.tarot.list-card': 'Thẻ Tarot',
  'menu.tarot.list-topic': 'Chủ đề',
  'menu.tarot.list-question': 'Câu hỏi',
  'menu.tarot.list-tarot': 'Giải nghĩa',
  'menu.notifications': 'Thông báo',
  'menu.notifications_create': 'Tạo thông báo',
  'menu.package.create-iap': 'Tạo mới iap',
  'menu.package.edit-iap': 'Cập nhật iap',
  'menu.zodiac.create-horoscopes': 'Tạo mới giải nghĩa',
  'menu.zodiac.edit-horoscopes': 'Cập nhật giải nghĩa',
  'menu.numerology.create-relations': 'Tạo mới mối quan hệ',
  'menu.numerology.edit-relations': 'Cập nhật mối quan hệ',
  'menu.tarot.edit-card': 'Cập nhật thẻ Tarot',
  'menu.tarot.edit-tarot': 'Cập nhật giải nghĩa',
};
