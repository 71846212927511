export default {
  'login.username': 'Tài khoản',
  'login.password': 'Mật khẩu',
  'login.username_required': 'Vui lòng nhập tài khoản!',
  'login.password_required': 'Vui lòng nhập mật khẩu!',
  'pages.login.rememberMe': 'Ghi nhớ đăng nhập',
  'pages.account.logout': 'Đăng xuất',
  'pages.new.name': 'Tiêu đề',
  'pages.new.image': 'Hình ảnh',
  'pages.new.url': 'Đường dẫn',
  'pages.action': 'Hành động',
  'common.confirm_delete': 'Xác nhận xóa',
  'common.content_delete': 'Bạn có chắc chắn muốn xóa?',
  'common.confirm': 'Xác nhận',
  'common.cancel': 'Hủy',
  'news.url_placeholder': 'Đường dẫn: https://...',
  'news.description': 'Mô tả',
  'news.content': 'Nội dung',
  'common.save': 'Lưu',
  'common.delete_success': 'Xoá dữ liệu thành công',
  'common.create_success': 'Tạo mới thành công',
  'common.update_success': 'Cập nhật thành công',
  'pages.quote.author': 'Tác giả',
  'pages.celebrity.name': 'Tên',
  'pages.zodiac.select': 'Chọn cung hoàng đạo',
  'pages.numerology.select': 'Chọn thần số học',
  'pages.iap.platform': 'Nền tảng',
  'pages.iap.price': 'Giá',
  'pages.iap.status': 'Trạng thái',
  'pages.iap.product_type': 'Loại sản phẩm',
  'pages.iap.currency': 'Loại tiền tệ',
  'pages.iap.package_id': 'ID gói',
  'pages.zodiac.from_date': 'Ngày bắt đầu',
  'pages.zodiac.name': 'Cung hoàng đạo',
  'pages.zodiac.image': 'Hình ảnh',
  'pages.celebrity.zodiac': 'Cung hoàng đạo',
  'pages.numerology.relation.target_numerology': 'Thần số học 1',
  'pages.numerology.relation.destination_numerology': 'Thần số học 2',
  'pages.receipt.product.price': 'Thanh toán',
  'pages.receipt.receipt_creation_date': 'Ngày thực hiện',
  'pages.receipt.user.name': 'Người giao dịch',
  'pages.receipt.product.title': 'Tên gói',
  'pages.user.name': 'Tên người dùng',
  'pages.user.email': 'Email',
  'pages.user.account_type': 'Tài khoản',
  'pages.user.provider': 'Nền tảng',
  'pages.user.birthday': 'Sinh nhật',
  'pages.noti.title': 'Tiêu đề',
  'pages.noti.date': 'Ngày gửi thông báo',
  'pages.notification.push_type': 'Thông báo tới',
  'pages.noti.category_type': 'Sự kiện',
  'pages.tarot.topic.name': 'Chủ đề',
  'pages.tarot.topic.question': 'Câu hỏi',
  'pages.notification.title': 'Tiêu đề',
  'pages.notification.body': 'Nội dung',
  'pages.notification.users': 'Người dùng',
  'pages.notification.schedule_time': 'Hẹn giờ',
  'pages.notification.topic': 'Chủ đề',
  'pages.tarot.tarot_card': 'Thẻ Tarot',
  'page.zodiac.horoscopes': 'Mối quan hệ',
  'pages.tarot.topic': 'Chủ đề',
  'pages.tarot.question': 'Câu hỏi',
  'common.time': 'Thời gian',
  'common.type': 'Giai đoạn',
  'common.weekly': 'Hàng tuần',
  'common.monthly': 'Hàng tháng',
  'common.yearly': 'Hàng năm',
  'common.daily': 'Hàng ngày',
  'common.from_date': 'Từ ngày',
  'common.to_date': 'Đến ngày',
};
