import { LogoutOutlined } from '@ant-design/icons';
import { history, useIntl } from '@umijs/max';
import type { MenuProps } from 'antd';
import React from 'react';
import HeaderDropdown from '../HeaderDropdown';

export type GlobalHeaderRightProps = {
  menu?: boolean;
  children?: React.ReactNode;
};

export const AvatarName = () => {
  return <span className="anticon">Admin</span>;
};

export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu, children }) => {
  const { formatMessage } = useIntl();

  const loginOut = async () => {
    if (window.location.pathname !== '/user/login') {
      localStorage.clear();
      history.replace({
        pathname: '/user/login',
      });
    }
  };

  const onMenuClick: MenuProps['onClick'] = (event) => {
    const { key } = event;
    if (key === 'logout') {
      loginOut();
      return;
    }
    history.push(`/account/${key}`);
  };

  const menuItems = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: formatMessage({ id: 'pages.account.logout' }),
    },
  ];

  return (
    <HeaderDropdown
      menu={{
        selectedKeys: [],
        onClick: onMenuClick,
        items: menuItems,
      }}
    >
      {children}
    </HeaderDropdown>
  );
};
